<template>
  <crud-form
    :schema="schema"
    :path="'menu'"
    :form-title="$t('addMenu')"
    :redirect-route="'MenuList'"
    :model="model"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/menu';
  import { form } from './forms/menu';

  export default {
    name: 'AddMenu',
    props: ['type'],
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
        form,
        model: {
          type: null,
        }
      }
    },
    beforeMount() {
      this.model = this.form;
      this.model.type = parseInt(this.type);
    }
  }
</script>
